import React from 'react';
import { useLocation } from 'react-router-dom';

const Sidebar = () => {
    const location = useLocation();
    const url = location.pathname;

    const handleLinkClick = (to: string) => {
        window.location.href = to;
    };

    return (
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                <li className="nav-heading">Administration</li>
                <li className="nav-item" style={{cursor:'pointer'}}>
                    <span
                        className={`nav-link ${url === '/dashboard' ? '' : 'collapsed'}`}
                        onClick={() => handleLinkClick('/dashboard')}
                    >
                        <i className="bi bi-dash-circle" />
                        <span>Dashboard</span>
                    </span>
                </li>
                <li className="nav-item" style={{cursor:'pointer'}}>
                    <span
                        className={`nav-link ${url === '/create-blog' ? '' : 'collapsed'}`}
                        onClick={() => handleLinkClick('/create-blog')}
                    >
                        <i className="bi bi-grid" />
                        <span>Create New Blog</span>
                    </span>
                </li>
                <li className="nav-item" style={{cursor:'pointer'}}>
                    <span
                        className={`nav-link ${url === '/blog' ? '' : 'collapsed'}`}
                        onClick={() => handleLinkClick('/blog')}
                    >
                        <i className="bi bi-grid" />
                        <span>Blogs</span>
                    </span>
                </li>
            </ul>
        </aside>
    );
};

export default Sidebar;
