import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Navbar = (props: { onToggle: (arg0: boolean) => void; }) => {
    const [isToggled, setToggled] = useState(false);
    const navigate = useNavigate();

    const toggle = () => {
        const newValue = !isToggled;
        setToggled(newValue);
        props.onToggle(newValue);
    };
    const handleLogout = () =>{
        toast.success("Logout Successfully")
        navigate('/');
        localStorage.clear()

    }

    return (
        <>
        <ToastContainer/>
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <p
                    className="logo d-flex align-items-center text-decoration-none"
                >
                    <img src="" alt=""/>
                    <span className="d-none d-lg-block">WTL ADMIN</span>
                </p>
                <i className="bi bi-list toggle-sidebar-btn" onClick={toggle}/>
            </div>
            {/* End Logo */}
            {/*<div className="search-bar">*/}
            {/*    <form*/}
            {/*        className="search-form d-flex align-items-center"*/}
            {/*        method="POST"*/}
            {/*        action="#"*/}
            {/*    >*/}
            {/*        <input*/}
            {/*            type="text"*/}
            {/*            name="query"*/}
            {/*            placeholder="Search"*/}
            {/*            title="Enter search keyword"*/}
            {/*        />*/}
            {/*        <button type="submit" title="Search">*/}
            {/*            <i className="bi bi-search"/>*/}
            {/*        </button>*/}
            {/*    </form>*/}
            {/*</div>*/}
            {/* End Search Bar */}
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    {/*<li className="nav-item d-block d-lg-none">*/}
                    {/*    <button className="nav-link nav-icon search-bar-toggle">*/}
                    {/*        <i className="bi bi-search"/>*/}
                    {/*    </button>*/}
                    {/*</li>*/}
                    {/* End Search Icon*/}
                    <li className="nav-item dropdown">
                        {/*<button className="nav-link nav-icon" data-bs-toggle="dropdown">*/}
                        {/*    <i className="bi bi-bell"/>*/}
                        {/*    <span className="badge bg-primary badge-number">4</span>*/}
                        {/*</button>*/}

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                  {/*          <li className="dropdown-header">*/}
                  {/*              You have 4 new notifications*/}
                  {/*              <a href="#">*/}
                  {/*<span className="badge rounded-pill bg-primary p-2 ms-2">*/}
                  {/*  View all*/}
                  {/*</span>*/}
                  {/*              </a>*/}
                  {/*          </li>*/}
                            {/*<li>*/}
                            {/*    <hr className="dropdown-divider"/>*/}
                            {/*</li>*/}
                            {/*<li className="notification-item">*/}
                            {/*    <i className="bi bi-exclamation-circle text-warning"/>*/}
                            {/*    <div>*/}
                            {/*        <h4>Lorem Ipsum</h4>*/}
                            {/*        <p>Quae dolorem earum veritatis oditseno</p>*/}
                            {/*        <p>30 min. ago</p>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <hr className="dropdown-divider"/>*/}
                            {/*</li>*/}
                            {/*<li className="notification-item">*/}
                            {/*    <i className="bi bi-x-circle text-danger"/>*/}
                            {/*    <div>*/}
                            {/*        <h4>Atque rerum nesciunt</h4>*/}
                            {/*        <p>Quae dolorem earum veritatis oditseno</p>*/}
                            {/*        <p>1 hr. ago</p>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <hr className="dropdown-divider"/>*/}
                            {/*</li>*/}
                            {/*<li className="notification-item">*/}
                            {/*    <i className="bi bi-check-circle text-success"/>*/}
                            {/*    <div>*/}
                            {/*        <h4>Sit rerum fuga</h4>*/}
                            {/*        <p>Quae dolorem earum veritatis oditseno</p>*/}
                            {/*        <p>2 hrs. ago</p>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <hr className="dropdown-divider"/>*/}
                            {/*</li>*/}
                            {/*<li className="notification-item">*/}
                            {/*    <i className="bi bi-info-circle text-primary"/>*/}
                            {/*    <div>*/}
                            {/*        <h4>Dicta reprehenderit</h4>*/}
                            {/*        <p>Quae dolorem earum veritatis oditseno</p>*/}
                            {/*        <p>4 hrs. ago</p>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <hr className="dropdown-divider"/>*/}
                            {/*</li>*/}
                            {/*<li className="dropdown-footer">*/}
                            {/*    <a href="#">Show all notifications</a>*/}
                            {/*</li>*/}
                        </ul>

                    </li>
                    {/* End Notification Nav */}
                    <li className="nav-item dropdown">
                        {/*<a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">*/}
                        {/*    <i className="bi bi-chat-left-text"/>*/}
                        {/*    <span className="badge bg-success badge-number">3</span>*/}
                        {/*</a>*/}
                        {/* End Messages Icon */}
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                            <li className="dropdown-header">
                                You have 3 new messages
                                <a href="#">
                  <span className="badge rounded-pill bg-primary p-2 ms-2">
                    View all
                  </span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            {/*<li className="message-item">*/}
                            {/*    <a href="#a">*/}
                            {/*        <img*/}
                            {/*            src=""*/}
                            {/*            alt=""*/}
                            {/*            className="rounded-circle"*/}
                            {/*        />*/}
                            {/*        <div>*/}
                            {/*            <h4>Maria Hudson</h4>*/}
                            {/*            <p>*/}
                            {/*                Velit asperiores et ducimus soluta repudiandae labore*/}
                            {/*                officia est ut...*/}
                            {/*            </p>*/}
                            {/*            <p>4 hrs. ago</p>*/}
                            {/*        </div>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <hr className="dropdown-divider"/>*/}
                            {/*</li>*/}
                            {/*<li className="message-item">*/}
                            {/*    <a href="#a">*/}
                            {/*        <img*/}
                            {/*            src=""*/}
                            {/*            alt=""*/}
                            {/*            className="rounded-circle"*/}
                            {/*        />*/}
                            {/*        <div>*/}
                            {/*            <h4>Anna Nelson</h4>*/}
                            {/*            <p>*/}
                            {/*                Velit asperiores et ducimus soluta repudiandae labore*/}
                            {/*                officia est ut...*/}
                            {/*            </p>*/}
                            {/*            <p>6 hrs. ago</p>*/}
                            {/*        </div>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <hr className="dropdown-divider"/>*/}
                            {/*</li>*/}
                            {/*<li className="message-item">*/}
                            {/*    <a href="#a">*/}
                            {/*        <img*/}
                            {/*            src=""*/}
                            {/*            alt=""*/}
                            {/*            className="rounded-circle"*/}
                            {/*        />*/}
                            {/*        <div>*/}
                            {/*            <h4>David Muldon</h4>*/}
                            {/*            <p>*/}
                            {/*                Velit asperiores et ducimus soluta repudiandae labore*/}
                            {/*                officia est ut...*/}
                            {/*            </p>*/}
                            {/*            <p>8 hrs. ago</p>*/}
                            {/*        </div>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <hr className="dropdown-divider"/>*/}
                            {/*</li>*/}
                            {/*<li className="dropdown-footer">*/}
                            {/*    <a href="#">Show all messages</a>*/}
                            {/*</li>*/}
                        </ul>
                        {/* End Messages Dropdown Items */}
                    </li>
                    {/* End Messages Nav */}
                    <li className="nav-item dropdown pe-3">
                        <a
                            className="nav-link nav-profile d-flex align-items-center pe-0"
                            href="#"
                            data-bs-toggle="dropdown"
                        >
                            <img src='https://cdn-icons-png.flaticon.com/512/3135/3135715.png' alt="Profile" className="rounded-circle"/>
                            <span className="d-none d-md-block dropdown-toggle ps-2">
                Admin
              </span>
                        </a>
                        {/* End Profile Iamge Icon */}
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>Admin</h6>
                                <span>Web Designer</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            {/*<li>*/}
                            {/*    <button*/}
                            {/*        className="dropdown-item d-flex align-items-center"*/}
                            {/*    >*/}
                            {/*        <i className="bi bi-person"/>*/}
                            {/*        <span>My Profile</span>*/}
                            {/*    </button>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <hr className="dropdown-divider"/>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <button*/}
                            {/*        className="dropdown-item d-flex align-items-center"*/}
                            {/*    >*/}
                            {/*        <i className="bi bi-gear"/>*/}
                            {/*        <span>Account Settings</span>*/}
                            {/*    </button>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <hr className="dropdown-divider"/>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <button*/}
                            {/*        className="dropdown-item d-flex align-items-center"*/}

                            {/*    >*/}
                            {/*        <i className="bi bi-question-circle"/>*/}
                            {/*        <span>Need Help?</span>*/}
                            {/*    </button>*/}
                            {/*</li>*/}
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            <li>
                                <button className="dropdown-item d-flex align-items-center" onClick={handleLogout}>
                                    <i className="bi bi-box-arrow-right"/>
                                    <span>Sign Out</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </header>
       </>

    );
};
export default Navbar;
