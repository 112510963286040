import React from "react";
import ErrorMsg from "../err-message/ErrorMsg";
import {Link} from 'react-router-dom';
import {SubmitHandler, useForm} from "react-hook-form";

interface IFormInput {
    email: string;
}

const EmailVerification = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IFormInput>();
    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        if (data) {
          console.log('data ', data)

        }
    };
  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Email Verification
                      </h5>
                    </div>
                    <form className="row g-3 needs-validation" onSubmit={handleSubmit(onSubmit)}>
                      <div className="col-12 col-md-12">
                        <label htmlFor="yourUsername" className="form-label">
                          Email
                        </label>
                        <div className="input-group has-validation">
                          <input
                              {...register("email", {required: `Email is required!`})}
                              name="email"
                              id="email"
                              type="email"
                              placeholder="Please Enter Email"
                              className="form-control p-2"
                          />
                        </div>
                        <ErrorMsg msg={errors.email?.message as string}/>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit">
                          Sent Email
                        </button>
                      </div>
                      <div className="col-12">
                        <p className="small mb-0">
                          <Link to="/">Login Page</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
export default EmailVerification;
