import {useEffect} from "react";
import './App.css';
import Login from './component/login/Login';
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import EmailVerification from "./component/email-verification/EmailVerification";
import NewPassword from "./component/new-password/NewPassword";
import Dashboard from "./component/dashboard/Dashboard";
import CreateBlog from "./component/create-blog/CreateBlog";
import Blog from './component/blog/blog';
import PrivateRoutes from "./PrivateRoutes";


function App() {

    return (
        <>
            {/*<BrowserRouter>*/}
            {/*    <Routes>*/}
            {/*        <Route path="/" element={<Login/>}/>*/}
            {/*        <Route path="/email-verification" element={<EmailVerification/>}/>*/}
            {/*        <Route path="/new-password" element={<NewPassword/>}/>*/}
            {/*        <Route path="/dashboard" element={<Dashboard/>}/>*/}
            {/*        <Route path="/create-blog" element={<CreateBlog/>}/>*/}
            {/*        <Route path='/blog' element={<Blog/>}/>*/}
            {/*    </Routes>*/}
            {/*</BrowserRouter>*/}
            <BrowserRouter>
                <Routes>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/create-blog" element={<CreateBlog/>}/>
                        <Route path="/create-blog/:id" element={<CreateBlog/>}/>
                        <Route path='/blog' element={<Blog/>}/>
                        <Route path="/dashboard" element={<Dashboard/>}/>
                    </Route>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/email-verification" element={<EmailVerification/>}/>
                    <Route path="/new-password" element={<NewPassword/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
